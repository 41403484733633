jQuery(function ($) {

  $('.floor-content .slideshow').slick({
    slidesToShow: 1,
    infinite: true,
    dots: true
  })

  $(window).on('scroll', function () {
    let currentTop = $(window).scrollTop()
    let elm = $('.floors-list .floor')
    elm.each(function () {
      let elemTop = $(this).offset().top - 200
      let elemBottom = elemTop + $(this).height()
      if (currentTop >= elemTop && currentTop <= elemBottom) {
        let id = $(this).attr('id')
        let navElem = $(`.floors-navigation .floor a[href="#${id}"]`)
        navElem.parent().addClass('active').siblings().removeClass('active')
      }
    })
  })

})


// floor detail
$(document).ready(function () {
  $('.popup-gallery .img').click(function () {
    var listItem = [];
    const popSourceThis = $(this).attr('data-id');
    const popTypeThis = $(this).attr('data-type');
    const lengthPopup = $('.popup-gallery .img').length;
    for (var i = 1; i < (lengthPopup + 1); i++) {
      const popClass = '.popup-' + i;
      const popSource = $(popClass).attr('data-id');
      const popType = $(popClass).attr('data-type');
      if (popSourceThis !== popSource) {
        if (popType === 'video') {
          listItem.push({src: popSource, type: 'iframe'})
        } else if (popType === 'image') {
          listItem.push({src: popSource})
        }
      }
    }

    if (popTypeThis === 'video') {
      listItem.unshift({src: popSourceThis, type: 'iframe'})
    } else if (popTypeThis === 'image') {
      listItem.unshift({src: popSourceThis})
    }

    $('.popup-gallery').magnificPopup({
      items: listItem,
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
      },
      type: 'image', // this is default type
      image: {
        tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
        titleSrc: function (item) {
          return item;
        }
      }
    });
  })
});
